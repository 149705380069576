<template>
  <div>
    <h6
      :label="`Antwoord ${index}`"
      variant="static"
    >
      {{ option.anwser }}
    </h6>
    <p>
      Stemmen: 0
    </p>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  }
}
</script>