<template>
  <poll-answer 
    v-for="option in options"
    :key="option.id"
    :option="option"
  />
</template>

<script>
import PollAnswer from '@/components/Poll/View/PollAnswer.vue';

export default {
  components: {
    PollAnswer
  },

  props: {
    options: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'update:modelValue'
  ],
}
</script>