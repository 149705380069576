<template>
  <div class="container-fluid">
    <div class="row">
      <basic-card>
        <template #header>
          <h5>Poll-vraag</h5>
        </template>

        <material-input 
          v-model="title"
          class="mb-2"
          label="Vraag"
          variant="static"
          disabled
        />

        <material-input
          v-model="description"
          class="mb-2"
          label="Beschrijving"
          variant="static"
          disabled
        />

        <material-input
          v-model="endDate"
          type="date"
          label="Einddatum"
          variant="static"
          disabled
        />

        <template #footer>
          <button class="btn btn-sm btn-dark float-end">Opslaan</button>
        </template>
      </basic-card>
    </div>

    <div class="row mt-4">
      <div class="grid grid5">
        <basic-card
          class="grid-item colspan3"
        >
          <template #header>
            <h5>Opties</h5>
          </template>

          <poll-answers
            ref="pollAnswersRef"
            :options="options"
          />
        </basic-card>

        <basic-card
          class="grid-item colspan2"
        >
          <template #header>
            <h5>Stemmers</h5>
          </template>

          <div class="grid grid2">
            <div
              v-for="user in voters"
              :key="user.id"
              class="grid-item"
            >
              <router-link
                :to="`/app-users/${user.id}`"
                class="d-flex gap-2 align-items-center mb-2"
              >
                <profile-picture
                  :width-and-height-in-pixels="40"
                  :background-image-url="user.profilePicture"
                  rounded
                />
                {{ user.userName }}
              </router-link>
            </div>
          </div>
        </basic-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BasicCard from '@/components/UI/BasicCard.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import PollAnswers from '@/components/Poll/View/PollAnswers.vue';
import ProfilePicture from '@/components/UI/ProfilePicture.vue';

import {
  getById as getPoll
} from '@/api/providers/poll';

export default {
  components: {
    BasicCard,
    MaterialInput,
    PollAnswers,
    ProfilePicture
  },

  data() {
    return {
      title: '',
      description: '',
      endDate: undefined,
      options: [],
      voters: []
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapActions('notification', { addNotification: 'add' },
    ),

    async getData() {
      try {
        const data = await getPoll(this.$route.params.id ?? -1);

        this.title = data.question;
        this.description = data.description;
        this.options = data.pollOptions;
        this.endDate = data.endDate.substring(0, 10);
        this.voters = data.voters;
      }
      catch {
        this.addNotification({
          description: 'Kan poll-gegevens niet weergeven',
          icon: { component: 'poll' }
        });
      }
    }
  }
}
</script>